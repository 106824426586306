<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" justify="center" align="center">
        <v-lazy transition="slide-y-transition">
          <patient-form :update="false" />
        </v-lazy>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import PatientForm from "@/components/forms/PatientForm.vue";

export default {
  name: "patient-form-view",
  components: {
    "patient-form": PatientForm
  },
  data() {
    return {};
  },
  mounted() {
    this.toggleBackgroundDark(true);
  },
  methods: mapMutations("ux", ["toggleBackgroundDark"])
};
</script>

<style scoped></style>
